.App {
  text-align: center;
}

.WalletCardSection {
  position: relative;
  margin-top: 55px;
  padding-top: 19px !important;
  padding: 0;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}

.WalletCardSection::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  display: block;
  height: 140px;
  background: #22B573;
}

.TransactionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0;
  box-sizing: border-box;
}

.Transactions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  color: #055531;
  margin: 20px auto 0;
}

.TransactionsViewAll {
  cursor: pointer;
  color: #768880;
}

.TransactionsLabel {
  font-size: 1.3rem;
  font-weight: 600;
}

.TransactionsList {
  width: 100%;
}

.Tontine {
  display: flex;
  overflow-x: auto;
  width: 100%;
}

.Tontine::-webkit-scrollbar {
  display: none;
}

.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 42vh;
}